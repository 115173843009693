"use client";
import {
  ARBITRUM_MAINNET,
  BASE_MAINNET,
  ETHEREUM_MAINNET,
  OPTIMISM_MAINNET,
  POLYGON_MAINNET,
} from "@/constants/chains";
import { getNetworkImage } from "@/utils/misc";
import { Shapes } from "lucide-react";
import { useSearchParams } from "next/navigation";
import { HomepageNetworkFilter } from "./Filters";
import { Suspense } from "react";

export default function HpFilters() {
  const searchParams = useSearchParams();
  const network = searchParams?.get("network") || "all";

  const supportedNetworks = [
    ETHEREUM_MAINNET,
    POLYGON_MAINNET,
    BASE_MAINNET,
    // OPTIMISM_MAINNET, // Not enough volume to show
    ARBITRUM_MAINNET,
  ];

  const items = supportedNetworks.map((network) => ({
    title: network.name,
    icon: (
      <img
        src={getNetworkImage(network.name.toLowerCase())}
        alt={network.name}
      />
    ),
    href: `/?network=${network.name.toLowerCase()}`,
  }));

  const itemsWithAll = [
    {
      title: "all",
      icon: <Shapes />,
      href: "/",
    },
    ...items,
  ];

  return (
    <Suspense>
      <HomepageNetworkFilter
        items={itemsWithAll}
        selectedItem={network || ""}
      />
    </Suspense>
  );
}
