"use client";

import * as React from "react";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

type Props = {
  mustBeAfterToday?: boolean;
  selectedDate: Date | null;
  onDateChange?: (date: Date | null) => void;
  placeholder?: string;
};

export function CustomDatePicker({
  mustBeAfterToday = false,
  selectedDate,
  onDateChange,
  placeholder,
}: Props) {
  const [open, setOpen] = React.useState(false);

  const handleDateSelect = (date: Date | undefined) => {
    // Calendar returns `undefined` if no date selected
    // We want to pass `null` if no date is selected, just like the original code
    const selected = date || null;
    if (onDateChange) {
      onDateChange(selected);
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          aria-label="Select a date"
          className={cn(
            "bg-white/10 text-base text-white w-full h-auto justify-start text-left hover:bg-primary hover:border-primary py-3 border-2 border-white/20  hover:text-white",
            !selectedDate && "text-light-gray"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {selectedDate ? (
            format(selectedDate, "MM/dd/yyyy")
          ) : (
            <span>{placeholder || "Select a date"}</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="rounded-xl  w-auto p-2 bg-dark-default text-white  border border-dark-pri neonLite"
        align="start"
      >
        <div className="rounded-xl">
          <Calendar
            // @ts-ignore
            mode="single"
            selected={selectedDate || undefined}
            onSelect={handleDateSelect}
            // Disable all dates before today if mustBeAfterToday is true
            disabled={mustBeAfterToday ? [{ before: new Date() }] : undefined}
            className="p-2 rounded-xl"
          />
        </div>
      </PopoverContent>
    </Popover>
  );
}
