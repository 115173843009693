"use client";
import { useRef } from "react";

import ReCAPTCHA from "react-google-recaptcha";
import TextInput from "../Forms/TextInput";
import { useNewUserTourModalStore } from "./ModalWrapper";

export default function NewUserForm() {
  const { setOpenNewUserTourModal } = useNewUserTourModalStore();
  const reRef = useRef<ReCAPTCHA>(null);

  return (
    <>
      <form
        className="grid gap-y-[12px] mt-[36x] mb-[12px] px-4 "
        // onSubmit={async (e) => {
        //   e.preventDefault();
        //   const recaptcha = await reRef?.current?.executeAsync();
        //   reRef?.current?.reset();

        // }}
      >
        <div>Did someone refer you?</div>
        <TextInput placeholder="Referral Code" />
        <ReCAPTCHA
          size="invisible"
          sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}
          ref={reRef}
          className="hidden"
        />
      </form>
    </>
  );
}
