"use client";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarTrigger,
} from "@/components/ui/menubar";
import { useMagic } from "@/context/MagicProvider";
import { setNotificationAsRead } from "@/server/actions/user/notifications/setNotificationAsRead";
import Tooltip from "@mui/material/Tooltip";
import Link from "next/link";
import SmolPrimaryBtn from "../../Forms/Button/SmolPrimaryBtn";
import IconNotification from "../../Icons/IconNotification";

type Props =
  | {
      id: string;
      type: string;
      message: string;
      read: boolean;
      href: string | null;
      btnText: string | null;
      createdAt: Date;
      updatedAt: Date;
    }[]
  | null;

export default function Notifications({
  notifications,
}: {
  notifications: Props;
}) {
  const { magic } = useMagic();
  async function showEnableMfaModal() {
    // @ts-ignore
    await magic?.user.showSettings({ page: "mfa" });
  }

  const formatDate = (dateString: string) => {
    const inputDate = new Date(dateString);
    const now = new Date();
    const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000); // Calculate 1 week ago

    const isMoreThanOneWeekOld = inputDate < oneWeekAgo;

    if (isMoreThanOneWeekOld) {
      // Format with month, day, year, and time
      const datePart = inputDate.toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      });
      const timePart = inputDate.toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      return `${datePart} @ ${timePart}`;
    } else {
      // Format with day of the week and time
      const dayOfWeekPart = inputDate.toLocaleString("en-US", {
        weekday: "long",
      });
      const timePart = inputDate.toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      return `${dayOfWeekPart} @ ${timePart}`;
    }
  };

  return (
    <div className="relative">
      <Menubar>
        <MenubarMenu>
          <MenubarTrigger>
            <Tooltip arrow placement="right" title="Notifications">
              <div
                className={`ml-[2.5px] bg-primary/40  shadow-xl rounded-lg border-transparent hover:scale-125 relative border transition-all ease-in-out 
      duration-300  w-[42px] h-[42px] flex items-center justify-center mx-auto`}
              >
                <IconNotification />
                {notifications && notifications?.length > 0 && (
                  <div
                    className="absolute -top-2 -right-2.5 w-5 h-5 bg-gradient-to-br from-pink via-pink to-red-pink 
            text-white text-xs rounded-full flex items-center justify-center"
                  >
                    {notifications?.length}
                  </div>
                )}
              </div>
            </Tooltip>
          </MenubarTrigger>
          <MenubarContent className="">
            {notifications && notifications?.length > 0 ? (
              <>
                {notifications?.map((notification, index) => (
                  <div key={index}>
                    <MenubarItem
                      asChild
                      className="w-full  p-3 hover:!bg-transparent group"
                    >
                      <div className="grid gap-3 group">
                        <div className="grid gap-2">
                          <div className="pt-2 font-semibold text-base line-clamp-2 md:max-w-[320px] md:min-w-[320px]">
                            {notification.message}
                          </div>
                          {notification.createdAt && (
                            <div className=" text-light-gray font-thin text-sm">
                              {/* @ts-ignore */}
                              {formatDate(notification?.createdAt)}
                            </div>
                          )}
                        </div>
                        <div className="flex items-center justify-between">
                          <button
                            onClick={async () =>
                              await setNotificationAsRead(notification.id)
                            }
                            aria-label="Mark as read"
                            className="text-dark-pri transition w-auto tracking-wide hover:brightness-125"
                          >
                            Mark as read
                          </button>
                          {notification.href &&
                          notification.href === "enable-mfa" ? (
                            <SmolPrimaryBtn onClick={showEnableMfaModal}>
                              View
                            </SmolPrimaryBtn>
                          ) : (
                            notification.href &&
                            notification.href.length > 0 && (
                              <Link
                                href={notification.href}
                                className={`w-auto flex items-center gap-x-1 rounded-md bg-primary px-4 py-2 text-center 
                                text-sm font-semibold text-white shadow-sm hover:brightness-110 focus-visible:outline
                                focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition `}
                              >
                                {notification.btnText &&
                                notification.btnText.length > 0
                                  ? notification.btnText
                                  : "View"}
                              </Link>
                            )
                          )}
                        </div>
                      </div>
                    </MenubarItem>
                    {index !== notifications.length - 1 && <MenubarSeparator />}
                  </div>
                ))}
              </>
            ) : (
              <div className="flex items-center justify-between w-full  p-3">
                <span className="text-white">No new notifications.</span>
              </div>
            )}
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
    </div>
  );
}
