"use client";
import React, { useState } from "react";
import LoadingDots from "../../Loading/LoadingDots";

type Props = {
  onClick?: () => void;
  children: React.ReactNode | string;
  loading?: boolean;
  disabled?: boolean;
  ariaLabel?: string;
  neon?: boolean;
  style?: string;
  type?: "button" | "submit" | "reset";
};

export default function SmolPrimaryBtn({
  onClick,
  children,
  loading,
  disabled,
  ariaLabel,
  neon = false,
  style,
  type = "button",
}: Props) {
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <button
      onClick={onClick && handleClick}
      aria-label={
        ariaLabel ? ariaLabel : typeof children === "string" ? children : ""
      }
      disabled={disabled}
      type={type}
      className={`w-auto flex items-center gap-x-1 rounded-md bg-primary px-4 py-2 text-center 
text-sm font-semibold text-white shadow-sm hover:brightness-110 focus-visible:outline
focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition ${style}`}
    >
      {loading ? <LoadingDots /> : children}
    </button>
  );
}
