"use client";
import { cn } from "@/lib/utils";
import {
  AnimatePresence,
  MotionValue,
  motion,
  useMotionValue,
  useSpring,
  useTransform,
} from "framer-motion";
import Link from "next/link";
import { useRef, useState } from "react";

type FloatingDockItem = {
  title: string;
  icon: React.ReactNode;
  href?: string;
  onClick?: () => void;
};

export const FloatingDockFilters = ({
  items,
  desktopClassName,
  selectedItem,
}: {
  items: FloatingDockItem[];
  desktopClassName?: string;
  selectedItem?: string;
}) => {
  return (
    <DockFilters
      items={items}
      className={desktopClassName}
      selectedItem={selectedItem}
    />
  );
};

const DockFilters = ({
  items,
  className,
  selectedItem,
}: {
  items: FloatingDockItem[];
  className?: string;
  selectedItem?: string;
}) => {
  const mouseX = useMotionValue(Infinity);

  return (
    <>
      {/* @ts-ignore */}
      <motion.div
        // @ts-ignore
        onMouseMove={(e) => mouseX.set(e.pageX)}
        onMouseLeave={() => mouseX.set(Infinity)}
        className={cn(
          "flex h-16 gap-4 items-end rounded-2xl border border-dark-default-1 px-4 pb-3",
          className
        )}
      >
        {items.map((item) => (
          <>
            {/* @ts-ignore */}
            <IconContainer
              mouseX={mouseX}
              key={item.title}
              title={item.title}
              icon={item.icon}
              href={item.href}
              onClick={item.onClick}
              isSelected={selectedItem === item.title}
            />
          </>
        ))}
      </motion.div>
    </>
  );
};

function IconContainer({
  mouseX,
  title,
  icon,
  href,
  onClick,
  isSelected,
}: {
  mouseX: MotionValue;
  title: string;
  icon: React.ReactNode;
  href?: string;
  onClick?: () => void;
  isSelected?: boolean;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [hovered, setHovered] = useState(false);

  const distance = useTransform(mouseX, (val) => {
    const bounds = ref.current?.getBoundingClientRect() ?? { x: 0, width: 0 };
    return val - bounds.x - bounds.width / 2;
  });

  const widthTransform = useTransform(distance, [-150, 0, 150], [40, 80, 40]);
  const heightTransform = useTransform(distance, [-150, 0, 150], [40, 80, 40]);
  const widthTransformIcon = useTransform(
    distance,
    [-150, 0, 150],
    [20, 40, 20]
  );
  const heightTransformIcon = useTransform(
    distance,
    [-150, 0, 150],
    [20, 40, 20]
  );

  const width = useSpring(widthTransform, {
    mass: 0.1,
    stiffness: 150,
    damping: 12,
  });

  const height = useSpring(heightTransform, {
    mass: 0.1,
    stiffness: 150,
    damping: 12,
  });

  const widthIcon = useSpring(widthTransformIcon, {
    mass: 0.1,
    stiffness: 150,
    damping: 12,
  });

  const heightIcon = useSpring(heightTransformIcon, {
    mass: 0.1,
    stiffness: 150,
    damping: 12,
  });

  const containerClasses = isSelected
    ? "bg-gradient-to-b from-primary to-[#970BD9] neon border-dark-pri scale-[115%]"
    : "bg-dark-gray border-primary/10";

  const content = (
    <>
      {/* @ts-ignore */}
      <motion.div
        ref={ref}
        style={{ width, height }}
        // @ts-ignore
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className={`border ${containerClasses} aspect-square rounded-full backdrop-blur-md flex items-center justify-center relative`}
        onClick={onClick}
      >
        <AnimatePresence>
          {hovered && (
            <>
              {/* @ts-ignore */}
              <motion.div
                initial={{ opacity: 0, y: 10, x: "-50%" }}
                animate={{ opacity: 1, y: 0, x: "-50%" }}
                exit={{ opacity: 0, y: 2, x: "-50%" }}
                // @ts-ignore
                className="px-2 py-0.5 whitespace-pre rounded-md 
              border bg-neutral-800 border-neutral-900 text-white absolute left-1/2 -translate-x-1/2 -top-8 w-fit text-xs"
              >
                {title}
              </motion.div>
            </>
          )}
        </AnimatePresence>
        {/* @ts-ignore */}
        <motion.div
          style={{ width: widthIcon, height: heightIcon }}
          // @ts-ignore
          className="flex items-center justify-center"
        >
          {icon}
        </motion.div>
      </motion.div>
    </>
  );

  // If href is provided, wrap content in a Link, else use a button
  return href ? (
    <Link href={href} aria-label={title}>
      {content}
    </Link>
  ) : (
    <button onClick={onClick} className="focus:outline-none" aria-label={title}>
      {content}
    </button>
  );
}
