"use client";
import { X } from "lucide-react";
import React, { useEffect } from "react";
import { IoMdClose } from "react-icons/io";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  modalTitle?: string;
  modalContent: string | React.ReactNode;
  width?: string;
};

export default function Modal({
  open,
  setOpen,
  modalTitle,
  modalContent,
  width = "max-w-xl",
}: Props) {
  const handleInnerClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("keydown", handleEscape);
      document.body.style.overflow = "hidden"; // Prevent background scrolling
    } else {
      document.removeEventListener("keydown", handleEscape);
      document.body.style.overflow = ""; // Allow for the page to scroll when closed.
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.body.style.overflow = ""; // Ensure cleanup
    };
  }, [open, setOpen]);

  if (!open) return null;

  return (
    <div
      style={{ zIndex: 120 }}
      className="fixed inset-0 bg-violet-900/30 backdrop-blur-[4px] transition h-full w-full "
      onClick={() => setOpen(false)}
    >
      <div
        className={`container flex items-end md:items-center justify-center h-full md:max-h-[90vh] mx-auto ${width}`}
      >
        <div
          className="absolute top-4 z-10 cursor-pointer right-4 flex h-10 w-10 items-center justify-center rounded-md 
p-2 text-light-gray bg-gray-50/20 hover:bg-gray-50/30 backdrop-blur-xl focus:outline-none focus:ring-2 focus:ring-indigo-500"
          onClick={() => setOpen(false)}
        >
          <span className="sr-only">Close menu</span>
          <X className="h-6 w-6" />
        </div>
        <div
          onClick={handleInnerClick}
          className="relative md:rounded-2xl  w-full md:w-auto 
          scrollbar-hide h-[90%] md:h-auto md:max-h-[90%]  "
        >
          <div className="flex flex-col ">
            <div className="lg:max-w-[98%] mx-auto">{modalContent}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
