"use client";
import { searchCollections } from "@/server/actions/collection/search/pgSearch";
import { fetchEnsUsername } from "@/server/actions/ens";
import { isEVMAddress } from "@/utils/network";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import debounce from "lodash/debounce";
import { Suspense, useCallback, useEffect, useState } from "react";
import { useSearchModalStore } from ".";
import LoadingDots from "../Loading/LoadingDots";
import ModalV2 from "../Modal/ModalV2";
import Contact from "./Contact";
import Guides from "./Guides";
import Jokes from "./Jokes";
import PopularCollections from "./PopularCollections";
import RecentSearches from "./RecentSearches";
import Results from "./Results";

interface CollectionResult {
  name: string;
  image: string;
  address: string;
  network: string;
}

export default function SearchModal() {
  const { searchModal, setSearchModal } = useSearchModalStore();
  const [loading, setLoading] = useState(false);
  const [uniqueCollections, setUniqueCollections] = useState<
    CollectionResult[] | null | any[]
  >([]);

  const [query, setQuery] = useState("");

  // Reset query when modal is closed
  useEffect(() => {
    if (searchModal === false) {
      setQuery("");
    }
  }, [searchModal]);

  const handleSearch = useCallback(
    debounce(async (query: string) => {
      if (query.length >= 3) {
        setLoading(true);
        const collections = await searchCollections(query);
        setUniqueCollections(collections);
        setLoading(false);
      } else {
        setUniqueCollections([]);
        setLoading(false);
      }
    }, 350),
    []
  );

  useEffect(() => {
    if (!query) {
      setUniqueCollections([]);
      setWalletAddress("");
      setLoading(false);
      return;
    }
    if (query.length < 3) {
      setUniqueCollections([]);
      setWalletAddress("");
      setLoading(false);
      return;
    }
    handleSearch(query);
  }, [query, handleSearch]);

  const [walletAddress, setWalletAddress] = useState<string>("");
  const [ensName, setEnsName] = useState<string | null>("");

  useEffect(() => {
    const fetchAndSet = async () => {
      setLoading(true);
      if (query && query.length >= 3) {
        if (isEVMAddress(query)) {
          try {
            const ensNameFetched = await fetchEnsUsername(query);
            setEnsName(ensNameFetched);
            setWalletAddress(query); // only set this after ENS is fetched
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } else {
        setUniqueCollections([]);
        setWalletAddress("");
        setLoading(false);
      }
    };

    fetchAndSet();
  }, [query]);

  const handleRecentSearchClick = (searchQuery: string) => {
    setQuery(searchQuery);
    handleSearch(searchQuery);
  };

  return (
    <>
      {searchModal && (
        <ModalV2
          width="!w-full sm:min-w-[600px] md:!max-w-[97%] lg:mt-8"
          open={searchModal}
          setOpen={setSearchModal}
          modalContent={
            <div className="h-full overflow-y-auto ">
              <div className="rounded-2xl p-8 bg-dark-default  mb-3  sm:w-auto w-full">
                <div className="grid ">
                  <div className="relative">
                    <form
                      onSubmit={(e) => e.preventDefault()}
                      className="rounded-md hover:border-dark-pri border border-dark-default-1 w-full relative text-white"
                    >
                      <MagnifyingGlassIcon className="absolute left-3 top-1/2 -translate-y-1/2 w-[22px]" />
                      <label htmlFor="search" className="sr-only">
                        search
                      </label>
                      <input
                        type="text"
                        id="search"
                        name="search"
                        autoFocus={true}
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        className="text-base pl-12 py-5 w-full bg-transparent ring-2 border-dark-pri ring-primary transition
                               focus:border-transparent focus:outline-none focus:ring-2 focus:ring-dark-pri text-white placeholder:text-white 
                               rounded-md"
                        placeholder="Search..."
                      />
                    </form>
                    {query && query.length > 3 && (
                      <button
                        onClick={() => setQuery("")}
                        aria-label="Clear search"
                        className="absolute right-3 top-1/2 -translate-y-1/2 z-10 cursor-pointer flex h-8 w-8 items-center justify-center rounded-md 
                               p-1 text-dark-pri bg-dark-pri/20 hover:bg-dark-pri/40 transition focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    )}
                  </div>

                  {loading && (
                    <div className="flex py-12 items-center justify-center">
                      <LoadingDots />
                    </div>
                  )}

                  <div>
                    {((walletAddress && walletAddress.length > 0) ||
                      (uniqueCollections && uniqueCollections.length > 0)) && (
                      <Results
                        uniqueCollections={uniqueCollections}
                        query={query}
                        walletAddress={walletAddress}
                        ensName={ensName}
                      />
                    )}

                    {/* @ts-ignore */}
                    <RecentSearches search={handleRecentSearchClick} />
                  </div>
                </div>
              </div>
              <div className="grid md:grid-cols-5 items-start rounded-2xl  relative ">
                <div
                  className="lg:max-h-[33%] z-50 overflow-y-auto scrollbar-hide md:col-span-3 rounded-2xl 
                 p-8  md:mr-3 sm:w-auto w-full bg-dark-default relative min-h-[400px]"
                >
                  <Suspense fallback={<LoadingDots />}>
                    <PopularCollections />
                  </Suspense>
                </div>
                <div className="min-h-[400px] lg:max-h-[33%] md:col-span-2 h-auto rounded-2xl grid items-start p-8  mb-6  sm:w-auto w-full bg-dark-default">
                  <div className="w-full mb-5">
                    <Guides />
                  </div>

                  <div className="mb-5">
                    <Contact setSearchModal={setSearchModal} />
                  </div>

                  <Jokes />
                </div>
              </div>
            </div>
          }
        />
      )}
    </>
  );
}
