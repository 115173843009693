"use client";

import BlockchainLogo from "@/components/ui/BlockchainLogo";
import { findChainIdByNetworkName } from "@/utils/network";
import { motion } from "framer-motion";
import { Suspense } from "react";
import CollectionImage from "./CollectionImage";
import CollectionName from "./CollectionName";
import NftImage from "./NftImage";
import NftName from "./NftName";
import styles from "./style.module.scss";

type Props = {
  network: string;
  address: string;
  tokenId: string;
  tokenName: string;
  nftImage: string;
  animationUrl?: string;
  collectionImage: string;
  collectionName: string;
  step: number;
};

export default function NFTCardUiV2({
  network,
  address,
  tokenId,
  tokenName,
  nftImage,
  animationUrl,
  collectionImage,
  collectionName,
  step,
}: Props) {
  return (
    <Suspense>
      {/* @ts-ignore */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        // @ts-ignore
        className="cursor-pointer group"
      >
        <div
          className="mb-6 relative rounded-t-lg group rounded-lg border 
        border-transparent hover:border-primary transition"
        >
          {step === 1 && (
            <img
              src="/check-circle-purple.png"
              className="h-0 group-hover:h-[50px] opacity-0 group-hover:md:opacity-100 absolute top-4 right-4 backdrop-blur-lg"
            />
          )}
          <div
            aria-label={tokenName}
            className={`${styles.imageContainer} group`}
          >
            <div className={styles.opacityHover}>
              <NftImage nftImage={nftImage} animationUrl={animationUrl} />
            </div>
          </div>

          <div
            className={`${styles.bottomContainer} group-hover:bg-dark-pri/10 transition`}
          >
            <div className="flex items-center justify-between space-x-2 ">
              <div className="flex items-center gap-x-2">
                {/******************  COLLECTION IMAGE  *******************/}
                {collectionImage && (
                  <div className="hidden xl:flex mr-4">
                    <CollectionImage
                      network={network}
                      address={address}
                      collectionImage={collectionImage}
                      collectionName={collectionName}
                    />
                  </div>
                )}

                <div className="space-y-[4px] text-[13px] flex flex-col w-full cursor-pointer">
                  {/******************  COLLECTION NAME  *******************/}
                  <div className="flex ">
                    <div className="xl:hidden flex mr-6">
                      <CollectionImage
                        network={network}
                        address={address}
                        collectionImage={collectionImage}
                        collectionName={collectionName}
                      />
                    </div>

                    <CollectionName
                      network={network}
                      address={address}
                      collectionName={collectionName}
                    />
                  </div>

                  <NftName
                    network={network}
                    address={address}
                    tokenName={tokenName}
                    tokenId={tokenId}
                  />
                </div>
              </div>

              <BlockchainLogo
                chainId={findChainIdByNetworkName(network)}
                imgStyle="!w-[17px] !h-[17px]  object-contain hidden xl:flex"
              />
            </div>
          </div>
        </div>
      </motion.div>
    </Suspense>
  );
}
