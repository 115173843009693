"use client";
import {
  ARBITRUM_MAINNET,
  BASE_MAINNET,
  ETHEREUM_MAINNET,
  OPTIMISM_MAINNET,
  POLYGON_MAINNET,
} from "@/constants/chains";
import { getPopularCollections } from "@/server/actions/collection/search/popularCollections";
import { getNetworkImage } from "@/utils/misc";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { useSearchModalStore } from ".";
import IconVerifiedCheck from "../Icons/IconVerifiedCheck";
import SkeletonLoader from "../SkeletonLoader";
import { FloatingDockFilters } from "./DockFilters";
import SectionHeading from "./SectionHeading";

export default function PopularCollections() {
  const [selectedBlockchain, setSelectedBlockchain] =
    useState(ETHEREUM_MAINNET);
  const [collections, setCollections] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const collectionsBySales = async () => {
    const network = selectedBlockchain.name.toLowerCase();
    const results = await getPopularCollections(network);

    if (results) {
      setCollections(results);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    collectionsBySales();
  }, [selectedBlockchain]);

  const supportedNetworks = [
    ETHEREUM_MAINNET,
    POLYGON_MAINNET,
    BASE_MAINNET,
    OPTIMISM_MAINNET,
    ARBITRUM_MAINNET,
  ];

  const items = supportedNetworks.map((network) => ({
    title: network.name,
    icon: (
      <img
        src={getNetworkImage(network.name.toLowerCase())}
        alt={network.name}
      />
    ),
    onClick: () => setSelectedBlockchain(network),
  }));
  const { setSearchModal } = useSearchModalStore();
  const path = usePathname();

  return (
    <div className="grid  relative w-auto ">
      <div
        className="z-40 grid gap-y-4 lg:flex  items-center justify-between  
    w-full mx-auto lg:min-w-[550px] h-auto relative "
      >
        <div
          className={`grid gap-y-6 ${
            path !== "/search-mobile"
              ? "lg:fixed mt-6 pt-4 pb-2  bg-dark-default"
              : "mb-4 md:pl-4"
          }  lg:flex justify-between 
        items-center lg:min-w-[550px]  `}
        >
          <div className="z-40  flex ">
            <SectionHeading title="Popular Collections" />
          </div>
          <div className="z-40  right-4">
            <FloatingDockFilters
              items={items}
              selectedItem={selectedBlockchain.name}
            />
          </div>
        </div>
      </div>

      <div
        className={`grid gap-1 gap-x-4 ${path !== "/search-mobile" && "pt-14"}`}
      >
        {collections.map((collection) => (
          <Link
            key={collection.address}
            scroll={true}
            href={`/collections/${collection.network}/${collection.address}`}
            onClick={() => setSearchModal(false)}
            className="relative w-full transition group flex gap-x-4 items-center 
            p-2 rounded-lg cursor-pointer hover:bg-dark-pri/20 even:bg-dark-pri/5 "
          >
            {collection.image_url && collection.image_url.length > 0 ? (
              <img
                src={collection.image_url}
                alt={collection.name}
                className="w-[40px] h-[40px] object-cover rounded-full"
              />
            ) : (
              <div className="w-[40px] h-[40px] object-contain rounded-full" />
            )}

            <div className="flex text-left justify-between w-full">
              <div
                className="flex items-center justify-start gap-x-2 z-20 w-full
                group-hover:text-dark-pri transition  line-clamp-2 text-sm overflow-visible"
              >
                {collection.name}{" "}
                {["approved", "verified"].includes(
                  collection.safelist_status?.toLowerCase()
                ) && (
                  <IconVerifiedCheck
                    circleStyle="w-4 h-4"
                    checkStyle="w-4 h-4"
                  />
                )}
              </div>
            </div>
          </Link>
        ))}
        {loading && (
          <>
            <SkeletonLoader containerShape="w-full h-[54px] mb-2" />
            <SkeletonLoader containerShape="w-full h-[54px] mb-2" />
            <SkeletonLoader containerShape="w-full h-[54px] mb-2" />
            <SkeletonLoader containerShape="w-full h-[54px] mb-2" />
            <SkeletonLoader containerShape="w-full h-[54px]" />
          </>
        )}
      </div>
      {/* Gradient Overlay */}
      <div className="z-40 hidden md:flex sticky -bottom-9 w-full h-[90px] bg-gradient-to-b from-dark-default/0 to-dark-default" />
    </div>
  );
}
