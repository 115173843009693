type Props = {
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  name?: string;
  type?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  required?: boolean;
  ref?: React.RefObject<HTMLInputElement>;
  defaultValue?: string | number | readonly string[] | undefined;
};

export default function TextInput({
  label,
  placeholder,
  value,
  onChange,
  id,
  name,
  type,
  disabled,
  autoFocus,
  onKeyPress,
  required,
  defaultValue,
  ref,
}: Props) {
  return (
    <>
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-medium leading-6  sm:pt-1.5"
        >
          {label}
        </label>
      )}
      <label htmlFor={id} className="sr-only">
        {label}
      </label>
      <input
        id={id}
        ref={ref}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        name={name}
        required={required}
        type={type}
        disabled={disabled}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        onKeyUp={onKeyPress}
        className="w-full flex-1 outline-none rounded-md border-2 border-white/20 bg-white/10 px-3.5 py-3 text-white shadow-sm  hover:border-dark-pri focus:border-dark-pri sm:leading-6"
      />
    </>
  );
}
