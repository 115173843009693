"use client";
import { useRef } from "react";
import { useFormStatus } from "react-dom";
import { useAlertStore } from "../Alert";
import PrimaryButton from "../Forms/Button/PrimaryBtn";
import subscribeToNewsletter from "../../../server/actions/newsletterSubscribe";
import ReCAPTCHA from "react-google-recaptcha";

export default function Newsletter() {
  const { setAlertMessage, setAlertType, setOpenAlert } = useAlertStore();

  const formRef = useRef<HTMLFormElement>(null);
  const reRef = useRef<ReCAPTCHA>(null);
  const { pending } = useFormStatus();

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    // Extract form data
    const formData = new FormData(formRef.current!);

    try {
      const recaptcha = await reRef?.current?.executeAsync();
      reRef?.current?.reset();
      await subscribeToNewsletter(formData, recaptcha);
      // Reset email
      formRef.current?.reset();
      setAlertType("success");
      setAlertMessage("You're subscribed! 🎉 ");
      setOpenAlert(true);
    } catch (error) {
      setAlertType("error");
      setAlertMessage("Hmm something went wrong, please try again.");
      setOpenAlert(true);
    }
  }
  return (
    <>
      <div className="py-16 ">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8  ">
          <div className="relative isolate rounded-lg overflow-hidden  bg-dark-default-1 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">
            <div className="hidden sm:flex absolute right-4 top-4 bg-dark-pri rounded-full h-[200px] w-[250px] blur-[120px]" />
            <div className="hidden sm:flex absolute left-12 bottom-4 bg-primary rounded-full h-[200px] w-[250px] blur-[120px]" />
            <h2 className="mx-auto max-w-2xl text-center text-3xl  tracking-tight font-extrabold drop-shadow-[0_2.8px_1.4px_rgba(0,0,0,1)] sm:text-4xl ">
              Never miss a drop.
            </h2>
            <p className="mx-auto mt-5 sm:mt-4 max-w-xl text-center text-lg leading-8 text-gray-300">
              Subscribe to our newsletter for exclusive content and 🔥 deals.
            </p>
            <form
              ref={formRef}
              onSubmit={(e) => handleSubmit(e)}
              className="mx-auto mt-10 flex flex-col sm:flex-row max-w-md gap-x-4 gap-y-4"
            >
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="min-w-0 outline-none flex-auto rounded-md border-2 border-white/20 bg-white/5 px-3.5 py-2 text-white shadow-sm  hover:border-dark-pri focus:border-dark-pri  sm:text-sm sm:leading-6"
                placeholder="Enter your email"
              />
              <ReCAPTCHA
                size="invisible"
                sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}
                ref={reRef}
                className="hidden"
              />
              <div className="w-full sm:w-[130px] mx-auto sm:mx-0">
                <PrimaryButton
                  type="submit"
                  style="w-full"
                  disabled={pending}
                  loading={pending}
                >
                  Subscribe
                </PrimaryButton>
              </div>
            </form>
            <svg
              viewBox="0 0 1024 1024"
              className=" sm:hidden absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
              aria-hidden="true"
            >
              <circle
                cx={512}
                cy={512}
                r={512}
                fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                fillOpacity="0.7"
              />
              <defs>
                <radialGradient
                  id="759c1415-0410-454c-8f7c-9a820de03641"
                  cx={0}
                  cy={0}
                  r={1}
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(512 512) rotate(90) scale(512)"
                >
                  <stop stopColor="#7775D6" />
                  <stop offset={1} stopColor="#E935C1" stopOpacity={0} />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
}
