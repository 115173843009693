"use client";
import { useMagic } from "@/context/MagicProvider";
import { logoutUser } from "@/server/actions/user/auth/logout";
import { getWalletAddrAndAvatarFromJwt } from "@/server/actions/user/profileMetadata/getWalletAddrAndAvatarFromJwt";
import { logout } from "@/utils/common";
import { motion } from "framer-motion";
import { CircleHelp, Gem, Mail, Settings, Sword, Tag } from "lucide-react";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { IoGameControllerOutline } from "react-icons/io5";
import { useDisconnect } from "wagmi";
import { create } from "zustand";
import IconCloseX from "../../Icons/IconCloseX";
import IconHeart from "../../Icons/IconHeart";
import IconMenu from "../../Icons/IconMenu";
import LoadingDots from "../../Loading/LoadingDots";
import {
  fadeInVariant,
  hideNavItemsVariant,
  liVariant,
  mobileMenuVariant,
  ulVariant,
} from "./animation";
import { FORUM, LEGAL_POLICIES } from "./static-links";
import styles from "./style.module.scss";

interface MobileMenuState {
  mobileMenu: boolean;
  setMobileMenu: (searchModal: boolean) => void;
}

export const useMobileMenuStore = create<MobileMenuState>((set) => ({
  mobileMenu: false,
  setMobileMenu: (mobileMenu) => set({ mobileMenu }),
}));

const MobileMenu = () => {
  const { mobileMenu, setMobileMenu } = useMobileMenuStore();
  const { magic } = useMagic();
  const { disconnect } = useDisconnect();

  const router = useRouter();

  const [loadingMyInventory, setLoadingMyInventory] = useState(false);

  const checkUserLoggedIn = async () => {
    const loggedIn = await magic?.user?.isLoggedIn();
    return loggedIn;
  };

  const handleMyInventory = async () => {
    setLoadingMyInventory(true);
    try {
      const { wallet } = await getWalletAddrAndAvatarFromJwt();
      if (wallet && wallet?.length > 0) {
        router.push(`/profile/${wallet}`);
      } else {
        const isUserLoggedIn = await checkUserLoggedIn();
        if (isUserLoggedIn) {
          const userData = await magic?.user.getMetadata();
          const publicAddress = userData?.publicAddress || "";
          router.push(`/profile/${publicAddress}`);
        } else {
          router.push("/search");
        }
      }
    } catch (error) {
      console.error("Error navigating to inventory:", error);
    } finally {
      setMobileMenu(false);
      setLoadingMyInventory(false);
    }
  };

  const handleLinkClick = (href: string) => {
    router.push(href);
    setMobileMenu(false);
  };

  const [loggingOut, setLoggingOut] = useState(false);

  const handleLogout = async () => {
    setLoggingOut(true);
    try {
      await logoutUser();
      await logout(magic);
      disconnect();
      window.open("/", "_self");
      window.location.reload();
    } catch (error) {
      console.error("Error logging out:", error);
    } finally {
      // Wait 2 seconds before setting logging out to false
      setTimeout(() => {
        setLoggingOut(false);
      }, 3000);
    }
  };

  const MOBILE_NAV_ITEMS = [
    {
      id: 0,
      navTitle: (
        <>
          <Sword className="rotate-[70deg] mx-auto" /> Inventory
        </>
      ),
      onclick: handleMyInventory,
    },
    {
      id: 1,
      navTitle: (
        <>
          {/* @ts-ignore */}
          <IoGameControllerOutline className="text-2xl mx-auto" /> Play Games
        </>
      ),
      href: "/games",
    },
    {
      id: 2,
      navTitle: (
        <>
          <Tag className="mx-auto" /> Sell
        </>
      ),
      href: "/sell",
    },

    {
      id: 3,
      navTitle: (
        <>
          <Gem className="text-xl mx-auto" />
          Rewards
        </>
      ),
      href: "/rewards",
    },

    {
      id: 4,
      navTitle: (
        <>
          <span className="mx-auto">
            <IconHeart />
          </span>
          Likes
        </>
      ),
      href: "/likes",
    },
    {
      id: 5,
      navTitle: (
        <>
          <Settings className="mx-auto" /> Settings
        </>
      ),
      href: "/settings",
    },

    {
      id: 7,
      navTitle: (
        <>
          <Mail className="mx-auto" />
          Contact us
        </>
      ),
      href: "/contact",
    },
    {
      id: 6,
      navTitle: (
        <>
          <CircleHelp className="mx-auto" />
          Help
        </>
      ),
      href: "https://help.rodeyo.com/",
    },
  ];

  return (
    <>
      {/* @ts-ignore */}
      <motion.button
        variants={hideNavItemsVariant}
        // @ts-ignore
        onClick={() => setMobileMenu(true)}
        className="p-2 cursor-pointer sm:hidden"
      >
        <IconMenu />
      </motion.button>
      {/* @ts-ignore */}
      <motion.nav
        initial="closed"
        animate={mobileMenu ? "opened" : "closed"}
        // @ts-ignore
        className="sm:hidden"
      >
        <div className={styles.menuContainer}></div>
        {loadingMyInventory && <LoadingDots />}
        {/* @ts-ignore */}
        <motion.div variants={mobileMenuVariant} className={styles.mobileMenu}>
          {/* @ts-ignore */}
          <motion.button
            variants={fadeInVariant}
            // @ts-ignore
            onClick={() => setMobileMenu(false)}
          >
            <IconCloseX />
          </motion.button>

          {/* @ts-ignore */}
          <motion.ul
            variants={ulVariant}
            // @ts-ignore
            className="grid grid-cols-2 gap-3 mx-3 mt-6 "
          >
            {MOBILE_NAV_ITEMS.map((navItem) => (
              <div key={navItem.id}>
                {/* @ts-ignore */}
                <motion.li whileTap={{ scale: 0.95 }}>
                  {/* @ts-ignore */}
                  <motion.div
                    variants={liVariant}
                    // @ts-ignore
                    className="py-6 flex flex-col items-center justify-center gap-3 text-center 
                  rounded-2xl border border-dark-pri/30 bg-dark-pri/10"
                    onClick={
                      navItem.href
                        ? () => handleLinkClick(navItem.href)
                        : navItem.onclick
                    }
                  >
                    {navItem.navTitle}
                  </motion.div>
                </motion.li>
              </div>
            ))}
          </motion.ul>
          <div className="mt-8" />
          {/* @ts-ignore */}
          <motion.div variants={fadeInVariant} className={styles.contact}>
            <div onClick={handleLogout}>
              {loggingOut ? <LoadingDots /> : "Log out"}
            </div>
            {FORUM.map((item) => (
              <div
                key={item.navTitle}
                onClick={() => handleLinkClick(item.href)}
              >
                {item.navTitle}
              </div>
            ))}
          </motion.div>
          <div className="mt-6" />
          {/* @ts-ignore */}
          <motion.div variants={fadeInVariant} className={styles.contact}>
            {LEGAL_POLICIES.map((policy) => (
              <div
                key={policy.navTitle}
                onClick={() => handleLinkClick(policy.href)}
              >
                {policy.navTitle}
              </div>
            ))}
          </motion.div>
        </motion.div>
      </motion.nav>
    </>
  );
};

export default MobileMenu;
