"use client";
import { useState } from "react";
import { FaPlay } from "react-icons/fa";
import Modal from "../Modal";
import Overlay from "../Overlay";

type Props = {
  url?: string;
};

export default function Trailer({ url }: Props) {
  const [modal, setModal] = useState(false);
  return (
    <>
      <button
        onClick={() => setModal(true)}
        aria-label="Play trailer"
        className="flex items-center gap-x-3 hover:text-dark-pri transition drop-shadow-[0_1.2px_1px_rgba(0,0,0,1)] "
      >
        {/* @ts-ignore */}
        <FaPlay />
        Trailer
      </button>
      <Overlay open={modal} setOpen={setModal}>
        {/* <Modal
        open={modal}
        setOpen={setModal}
        width="!w-full sm:min-w-[600px] md:!max-w-[85%]"
      > */}
        <div
          className="h-auto min-h-[300px] max-h-[90%] !w-full sm:min-w-[600px] md:!max-w-[85%]"
          style={{
            aspectRatio: "16 / 9",
          }}
        >
          <iframe
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            src={`${url}?rel=0`}
            frameBorder="0"
            sandbox="allow-same-origin allow-scripts"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </Overlay>
    </>
  );
}
