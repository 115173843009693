import { getNetworkImage } from "@/utils/misc";
import Link from "next/link";

import SectionHeading from "./SectionHeading";
import IconVerifiedCheck from "../Icons/IconVerifiedCheck";
import { useSearchModalStore } from ".";
import { ArrowUpRight, Wallet } from "lucide-react";
import { shortenAddress } from "@/utils/common";

interface NFTResult {
  name: string;
  image: string;
  collection: any;
  tokenId: string;
  address: string;
  network: string;
}

type Props = {
  uniqueCollections: any[] | null;
  query: string;
  walletAddress?: string | null;
  ensName?: string | null;
};

export default function Results({
  uniqueCollections,
  query,
  walletAddress,
  ensName,
}: Props) {
  const { setSearchModal } = useSearchModalStore();

  const handleClick = () => {
    setSearchModal(false);
    saveSearchQuery(query);
  };

  const saveSearchQuery = (query) => {
    if (typeof window !== "undefined") {
      const searches = JSON.parse(
        localStorage.getItem("recentSearches") || "[]"
      );
      // Add the new query to the start of the array
      searches.unshift(query);
      // Remove duplicates
      const uniqueSearches = Array.from(new Set(searches));
      // Limit num of saved searches to avoid bloating localStorage
      const limitedSearches = uniqueSearches.slice(0, 5);
      localStorage.setItem("recentSearches", JSON.stringify(limitedSearches));
    }
  };

  const safelistStatus = ["approved", "verified"];
  return (
    <div className=" mt-6">
      <SectionHeading title="Search Results" icon="👀" />
      <div className="grid justify-items-start gap-2   pt-4">
        {uniqueCollections && uniqueCollections.length > 0 && (
          <div className="w-full justify-start ">
            <div className="grid sm:grid-cols-2 gap-4 w-full pt-2  lg:max-h-[400px] overflow-y-auto scrollbar-hide relative">
              {uniqueCollections.map((collection) => (
                <Link
                  key={collection?.address}
                  className="overflow-clip relative w-full transition group flex gap-x-4 items-center px-4
                   py-6 rounded-lg cursor-pointer hover:bg-dark-pri/20 bg-dark-pri/10"
                  href={`/collections/${collection?.network}/${collection?.address}`}
                  onClick={handleClick}
                >
                  {collection?.image && collection.image.length > 0 ? (
                    <img
                      src={collection?.image || "/image-not-found.png"}
                      alt={collection?.name}
                      className="w-[60px] h-[60px] object-cover rounded-full"
                    />
                  ) : (
                    <div className="w-[60px] h-[60px] object-contain rounded-full" />
                  )}

                  <div className="flex text-left flex-col justify-center gap-y-1 ">
                    <p className="text-lg z-20 font-semibold group-hover:text-dark-pri transition max-w-[320px] line-clamp-2">
                      {collection?.name}
                    </p>
                    <div className="z-20 flex items-center gap-x-6 mt-3 text-sm">
                      {safelistStatus.includes(
                        collection?.safelist_status?.toLowerCase()
                      ) && (
                        <div className="flex items-center gap-x-2 ">
                          <IconVerifiedCheck
                            circleStyle="w-4 h-4"
                            checkStyle="w-4 h-4"
                          />

                          <span>Verified</span>
                        </div>
                      )}
                      <div className="z-20 flex items-center gap-x-1  ">
                        <img
                          className="w-4 h-4 object-contain"
                          src={getNetworkImage(collection?.network)}
                          alt=""
                        />
                        <p className="capitalize group-hover:text-dark-pri transition">
                          {collection?.network}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
              {/* Gradient Overlay */}
              <div className="z-40 sticky sm:col-span-2 -bottom-9 w-full h-[90px] bg-gradient-to-b from-dark-default/0 to-dark-default" />
            </div>
          </div>
        )}

        {walletAddress && walletAddress.length > 0 && (
          <div className="w-full justify-start pt-4 border-b border-dark-default-1 pb-4">
            <p className="text-xl font-semibold pt-2 pb-4">Wallets</p>
            <Link
              href={`/profile/${query}`}
              className="group w-full transition flex gap-x-5 items-center p-4 rounded-lg cursor-pointer bg-dark-pri/10 hover:bg-dark-pri/20"
            >
              <div className="w-[60px] h-[60px] bg-dark-pri rounded-md flex items-center justify-center">
                <Wallet className="" />
              </div>

              <div className="flex text-left flex-col justify-center group-hover:text-dark-pri transition gap-y-1">
                <div className="font-semibold max-w-[250px] truncate flex items-center gap-x-3  ">
                  {ensName || shortenAddress(query, 8)} <ArrowUpRight />
                </div>
                <p className="text-xs max-w-[250px] truncate">Wallet Address</p>
              </div>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
