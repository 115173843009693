"use client";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import IconRefresh from "../../Icons/IconRefresh";
import SmolPrimaryBtn from "../../Forms/Button/SmolPrimaryBtn";
import SectionHeading from "../SectionHeading";
import { getJokes } from "@/server/actions/collection/search/jokes/getJokes";
import { jokesLocal } from "@/server/actions/collection/search/jokes/data";
import Link from "next/link";

export default function Jokes() {
  const [tellAJoke, setTellAJoke] = useState(false);
  const [randNum, setRandNum] = useState(0);
  const [counter, setCounter] = useState(0);
  const [jokes, setJokes] = useState<any[]>([]);

  const getRandNumber = () => {
    return Math.floor(Math.random() * jokes.length);
  };

  const getJoke = () => {
    // Rick roll after 5 jokes
    if (counter > 4) {
      setCounter(0);
      window.open(
        "https://www.yout-ube.com/watch?v=dQw4w9WgXcQ",
        "_blank",
        "noopener noreferrer"
      );
      return;
    }
    const randomNumber = getRandNumber();
    setRandNum(randomNumber);
    setCounter(counter + 1);
  };

  async function getJokesLocal() {
    const allJokes = await getJokes();
    if (allJokes && allJokes.length > 0) {
      setJokes(allJokes);
    } else {
      setJokes(jokesLocal);
    }
  }

  useEffect(() => {
    getJokesLocal();
  }, []);



  return (
    <div>
      <div className="w-full lg:flex justify-between ">
        <SectionHeading title="Bored?" icon="😮‍💨" />
        {!tellAJoke && (
          <SmolPrimaryBtn
            style="mt-4 lg:mt-0"
            onClick={() => {
              setTellAJoke(true);
              if (jokes.length > 0) {
                setRandNum(Math.floor(Math.random() * jokes.length));
              }
            }}
          >
            Tell me a joke
          </SmolPrimaryBtn>
        )}
      </div>
      <div className="mt-3 relative min-h-[100px] w-full">
        <AnimatePresence>
          {tellAJoke && (
            <>
              {/* @ts-ignore */}
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                key={jokes[randNum]}
                animate={{
                  opacity: 1,
                  y: 1,
                  transition: { duration: 0.5 },
                }}
                exit={{ opacity: 0, y: 100 }}
                // @ts-ignore
                className="absolute top-2 grid max-w-[300px] items-center justify-center 
          gap-y-[12px] text-center  font-normal text-light-gray xl:max-w-[340px] w-full"
              >
                {jokes[randNum].joke}
                {jokes[randNum].user && (
                  <div className="flex items-center justify-center gap-x-2">
                    <span className="text-sm opacity-80">Submitted by</span>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/profile/${jokes[randNum].user.wallet}`}
                      aria-label="Go to profile"
                    >
                      <img
                        src={jokes[randNum].user.avatar}
                        alt="User Avatar"
                        className="w-6 h-6 object-cover rounded-full"
                      />
                    </Link>
                  </div>
                )}
                <button
                  onClick={() => getJoke()}
                  aria-label="Refresh Joke"
                  className="mx-auto text-dark-pri"
                >
                  <IconRefresh />
                </button>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
