"use client";
import BannerOverlay from "@/components/ui/BannerOverlay";
import IconWallet from "@/components/ui/Icons/IconWallet";
import { useMagic } from "@/context/MagicProvider";
import { ethers } from "ethers";
import { Info } from "lucide-react";
import { useEffect } from "react";
import { useAccount } from "wagmi";
import { create } from "zustand";

interface TopUpBannerState {
  openTopUpBanner: boolean;
  setTopUpBanner: (openTopUpBanner: boolean) => void;
  neededFunds: string;
  setNeededFunds: (neededFunds: string) => void;
  currencySymbol: string;
  setCurrencySymbol: (currencySymbol: string) => void;
}

export const useTopUpStore = create<TopUpBannerState>((set) => ({
  openTopUpBanner: false,
  setTopUpBanner: (openTopUpBanner) => set({ openTopUpBanner }),
  neededFunds: "",
  setNeededFunds: (neededFunds) => set({ neededFunds }),
  currencySymbol: "",
  setCurrencySymbol: (currencySymbol) => set({ currencySymbol }),
}));

export default function BannerTopUpFunds({
  children,
}: {
  children: React.ReactNode;
}) {
  const {
    openTopUpBanner,
    setTopUpBanner,
    setCurrencySymbol,
    setNeededFunds,
    neededFunds,
    currencySymbol,
  } = useTopUpStore();

  const { magic } = useMagic();
  const { isConnected } = useAccount();
  const handleClick = () => {
    setTopUpBanner(false);
    setNeededFunds("");
    setCurrencySymbol("");
    magic?.wallet.showUI();
  };

  // const getProviderFromMagic = async () => {
  //   const magicProvider = await magic!.wallet.getProvider();
  //   const ethersProvider = new ethers.BrowserProvider(magicProvider);
  //   return ethersProvider;
  // };

  // async function getMagicUserBalance() {
  //   const provider = await getProviderFromMagic();
  //   const userInfo = await magic?.user.getInfo();
  //   console.log("userInfo: ", userInfo);
  //   const userAddress = userInfo?.publicAddress;
  //   const userBalance = await provider.getBalance(userAddress || "");
  //   console.log("userBalance: ", userBalance);
  // }

  // useEffect(() => {
  //   if (isConnected) return;
  //   getMagicUserBalance();
  // }, [magic]);

  return (
    <>
      {openTopUpBanner && (
        <BannerOverlay open={openTopUpBanner} setOpen={setTopUpBanner}>
          <div className="flex items-center justify-center w-full gap-4">
            <div className="grid w-full justify-center ">
              <div className="mt-2  md:mt-0 grid gap-4 lg:gap-6 md:flex items-center gap-x-6 w-full ">
                {neededFunds && currencySymbol ? (
                  <div className="text-[16px]">
                    You need {neededFunds ? Number(neededFunds).toFixed(4) : ""}{" "}
                    more {currencySymbol} to buy this.
                  </div>
                ) : (
                  <div className="text-[16px] w-full min-w-[280px] flex items-center gap-x-2">
                    <Info className="w-4" />
                    <span>You need more funds to buy this.</span>
                  </div>
                )}
                {isConnected ? (
                  <>{children}</>
                ) : (
                  <button
                    onClick={handleClick}
                    aria-label="Top up wallet"
                    className="overflow-clip w-full max-w-[240px] rounded-lg  px-8 py-3 text-base font-medium 
                    hover:brightness-110 focus:outline-none focus:ring-0 focus:ring-transparent
                    focus:ring-offset-0 neonPurple transition flex items-center justify-center gap-x-1 bg-gradient-to-br from-primary to-[#970BD9] border border-dark-pri"
                  >
                    Top Up <IconWallet className="w-5  ml-1" /> Wallet{" "}
                    <span aria-hidden="true" className="ml-2">
                      &rarr;
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </BannerOverlay>
      )}
    </>
  );
}
