"use client";
import { parseEther } from "@ethersproject/units";
import { Seaport } from "@opensea/seaport-js";
import { ItemType } from "@opensea/seaport-js/lib/constants";
import { initializeSeaport } from "./initialize";
import { addListingToDB } from "@/server/actions/nft/listings/addListingToDB";
import { ethers } from "ethers";

type Props = {
  provider?: any;
  signer?: any;
  sellerAddress: string;
  network: string;
  address: string;
  tokenId: string;
  tokenType: string;
  price: string;
  cryptoCurrency: string;
  startDate: bigint;
  endDate: bigint;
};

export const createSeaportListing = async ({
  provider,
  signer,
  sellerAddress,
  network,
  address,
  tokenId,
  tokenType,
  price,
  cryptoCurrency, // e.g., 'ETH'
  startDate,
  endDate,
}: Props) => {
  if (provider && !provider.getSigner && !signer) {
    console.error("Invalid provider: getSigner method not found.");
    return;
  }
  // @ts-ignore
  const seaport: Seaport = initializeSeaport(signer ? signer : provider);

  const itemType = tokenType.includes("721")
    ? ItemType.ERC721
    : tokenType.includes("1155")
    ? ItemType.ERC1155
    : ItemType.ERC721;

  const priceInCryptoUnits = parseEther(price).toString();

  const { executeAllActions } = await seaport.createOrder(
    {
      // NFT being sold
      offer: [
        {
          itemType: itemType,
          token: address,
          identifier: tokenId,
          amount: "1", // TO DO: make quantity to be sold dynamic
        },
      ],
      // Payment for the NFT
      consideration: [
        {
          amount: priceInCryptoUnits,
          recipient: sellerAddress,
          // token: currencyAddresses[0].address,
        },
      ],
      allowPartialFills: false,
      startTime: startDate,
      endTime: endDate,
    },
    sellerAddress
  );
  try {
    const transactionReceipt = await executeAllActions();

    console.log("Transaction receipt:", transactionReceipt);
    const order_hash = await seaport.getOrderHash(
      transactionReceipt.parameters
    );

    const seller = transactionReceipt.parameters.offerer;

    // Convert cryptoCurrency price to float
    const cryptoAmount = parseFloat(price);

    // **Convert startTime and endTime to Date objects**
    const startTimeUnixSeconds = Number(
      transactionReceipt.parameters.startTime
    );
    const endTimeUnixSeconds = Number(transactionReceipt.parameters.endTime);

    const startDateObj = new Date(startTimeUnixSeconds * 1000);
    const endDateObj = new Date(endTimeUnixSeconds * 1000);

    // Save to Database Listings table
    const nft = await addListingToDB({
      order_hash: order_hash,
      parameters: transactionReceipt.parameters,
      signature: transactionReceipt.signature,
      formattedCryptoPrice: cryptoAmount,
      currency: cryptoCurrency,
      network: network,
      address: address,
      tokenId: tokenId,
      seller: seller,
      startDate: startDateObj,
      endDate: endDateObj,
    });

    console.log("Order hash:", order_hash);
    return transactionReceipt;
  } catch (error) {
    console.error("Transaction failed:", error);
    // throw error;
  }
};
