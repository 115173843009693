import Link from "next/link";
import { useState } from "react";
import Modal from "../Modal";
import SectionHeading from "./SectionHeading";

export default function Guides() {
  const [modal, setModal] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const handleClickVideo = (video: string) => {
    setVideoSrc(video);
    setModal(true);
  };
  const GUIDES = [
    // {
    //   name: "What's a wallet?",
    //   video: "https://www.youtube.com/embed/6VHA1rT803w",
    // },
    {
      name: "How do I earn rewards?",
      href: "https://www.rodeyo.com/rewards",
    },
    {
      name: "What are your fees?",
      href: "https://help.rodeyo.com/guides/fees",
    },
    {
      name: "How can I sell?",
      href: "https://help.rodeyo.com/guides/how-to-sell-nfts",
    },
  ];

  return (
    <>
      <div className="grid mt-12 sm:mt-0 gap-4">
        <div className="pb-2 ">
          <SectionHeading title="Guides" icon=" 📜" />
          <div className="grid items-start gap-x-4 gap-y-4 pt-4">
            {GUIDES?.map((guide) => (
              <Link
                key={guide?.name}
                href={guide?.href}
                target="_blank"
                rel="noopener noreferrer"
                className="opacity-90 text-left hover:opacity-100 transition hover:scale-105"
              >
                {guide?.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <Modal
        open={modal}
        setOpen={setModal}
        width="!w-full sm:min-w-[600px] md:!max-w-[85%]"
      >
        <div
          style={{
            position: "relative",
            paddingBottom: "56.25%", // Maintains a 16:9 aspect ratio
            height: 0,
            overflow: "hidden",
          }}
        >
          <iframe
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            src={`${videoSrc}?rel=0`}
            frameBorder="0"
            sandbox="allow-same-origin allow-scripts"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </>
  );
}
