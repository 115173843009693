import PrimaryButton from "@/components/ui/Forms/Button/PrimaryBtn";
import { handleUserLogin } from "@/server/actions/user/loginAndReg";
import showToast from "@/utils/showToast";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { Wallet } from "lucide-react";
import { useRouter } from "next/navigation";
import { useAccountEffect } from "wagmi";
import { useLoginModalStore } from "./LoginModal/ModalWrapper";
import RainbowKitWalletModal, {
  useWalletModalStore,
} from "./RainbowKitWalletModal";
import IconWallet from "@/components/ui/Icons/IconWallet";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import { validateHuman } from "@/server/actions/user/recaptcha";
// import { useNewUserTourModalStore } from "@/components/ui/NewUserTourModal/ModalWrapper";

type Props = {
  avatar?: string;
};

export const BtnRainbowKit = ({ avatar }: Props) => {
  const router = useRouter();
  const { setOpenLoginModal } = useLoginModalStore();
  const reRef = useRef<ReCAPTCHA>(null);
  // const { setOpenNewUserTourModal } = useNewUserTourModalStore();
  return (
    <>
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openAccountModal,
          openChainModal,
          openConnectModal,
          authenticationStatus,
          mounted,
        }) => {
          const ready = mounted && authenticationStatus !== "loading";
          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus || authenticationStatus === "authenticated");

          useAccountEffect({
            onConnect(data) {
              setOpenLoginModal(false);
              (async () => {
                try {
                  const result = await handleUserLogin({
                    walletAddress: data.address || account?.address,
                  });
                  router.refresh();
                  // if (result && result.isNewUser === true) {
                  //   setOpenNewUserTourModal(true);
                  // }
                } catch (error) {
                  console.error("Login error:", error);
                  showToast({ message: "Login failed", type: "error" });
                }
              })(); // Invoke the async function
            },
          });

          const { setOpen } = useWalletModalStore();

          return (
            <div
              {...(!ready && {
                "aria-hidden": true,
                style: {
                  opacity: 0,
                  pointerEvents: "none",
                  userSelect: "none",
                },
              })}
            >
              {(() => {
                if (!connected) {
                  return (
                    <form>
                      <ReCAPTCHA
                        size="invisible"
                        sitekey={
                          process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY
                        }
                        ref={reRef}
                        className="hidden"
                      />
                      <PrimaryButton
                        onClick={async (e) => {
                          e?.preventDefault();
                          try {
                            const recaptcha =
                              await reRef?.current?.executeAsync();
                            reRef?.current?.reset();
                            // validate human
                            // 1) Check for bots via reCAPTCHA
                            const human = await validateHuman(recaptcha || "");

                            // If bot, throw error
                            if (!human) {
                              throw new Error("");
                            }
                            openConnectModal();
                          } catch (error) {
                            console.error("Login error:", error);
                            showToast({
                              message: "Login failed",
                              type: "error",
                            });
                          }
                        }}
                        type="button"
                        style="w-full flex-grow !bg-gradient-to-r !from-primary !to-[#970BD9] 
                      focus:!outline-none focus:!ring-0 focus:!ring-transparent 
      focus:!ring-offset-0 focus:!ring-offset-transparent"
                      >
                        <div className=" flex items-center justify-center gap-x-2 w-full text-white/90 py-1 md:py-0">
                          <Wallet className="w-5" /> Connect Wallet
                        </div>
                      </PrimaryButton>
                    </form>
                  );
                }
                if (chain.unsupported) {
                  return (
                    <button
                      onClick={openChainModal}
                      aria-label="Change Network"
                      type="button"
                    >
                      Wrong network
                    </button>
                  );
                }
                return (
                  <>
                    <div className="">
                      <button
                        type="button"
                        onClick={() => setOpen(true)}
                        aria-label="Open Wallet Modal"
                        className={`w-auto overflow-clip
      px-4  bg-primary py-2 text-base font-light md:font-medium 
        hover:brightness-125 relative focus:outline-none transition rounded-md flex-1 flex items-center justify-center gap-x-2`}
                      >
                        <IconWallet className="sm:hidden max-w-[26px]  min-w-[26px]" />

                        {chain.iconUrl && (
                          <img
                            alt={chain.name ?? "Chain icon"}
                            src={
                              chain && chain.id === 1
                                ? `/network/${chain.id}.png`
                                : chain.iconUrl
                            }
                            className="hidden sm:flex h-5 w-5 min-w-[20px] object-cover sm:object-contain group-hover:scale-105 rounded-full"
                          />
                        )}
                        <span className="hidden sm:flex">
                          {account.displayBalance}
                        </span>
                      </button>
                    </div>
                    <RainbowKitWalletModal
                      formattedAddress={account.address}
                      balance={account.displayBalance}
                      avatar={avatar}
                      openChainModal={openChainModal}
                      chainIconUrl={
                        chain && chain.id === 1
                          ? `/network/${chain.id}.png`
                          : chain.iconUrl
                      }
                    />
                  </>
                );
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>
    </>
  );
};
