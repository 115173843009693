"use client";
import React from "react";
import SmolPrimaryBtn from "../Forms/Button/SmolPrimaryBtn";
import SectionHeading from "./SectionHeading";

type Props = {
  search: (querySearch: any) => Promise<void>;
};

export default function RecentSearches({ search }: Props) {
  const [searches, setSearches] = React.useState([]);

  const [removeSearches, setRemoveSearches] = React.useState(false);

  // Get recent searches from local storage
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const storedSearches = JSON.parse(
        localStorage.getItem("recentSearches") || "[]"
      );
      setSearches(storedSearches);
    }
  }, [removeSearches]);

  // Delete recent searches from local storage
  const deleteRecentSearches = () => {
    localStorage.removeItem("recentSearches");
    setSearches([]);
    setRemoveSearches(!removeSearches);
  };

  return (
    <>
      {searches.length > 0 && (
        <div className="mt-4  grid w-full md:flex justify-between">
          {/* <SectionHeading title="Recent Searches" icon="⚡️" /> */}

          <div className="flex flex-wrap gap-x-4 gap-y-4">
            {searches.map((query) => (
              <SmolPrimaryBtn key={query} onClick={() => search(query)}>
                {query}
              </SmolPrimaryBtn>
            ))}
          </div>
          <button
            onClick={deleteRecentSearches}
            aria-label="Delete recent searches"
            className="ml-auto sm:ml-8 text-sm font-normal hover:underline text-dark-pri cursor-pointer"
          >
            delete recent searches
          </button>
        </div>
      )}
    </>
  );
}
