"use client";

import { create } from "zustand";
import IconSearch from "../Icons/IconSearch";
import Link from "next/link";

interface SearchModalState {
  searchModal: boolean;
  setSearchModal: (searchModal: boolean) => void;
}

export const useSearchModalStore = create<SearchModalState>((set) => ({
  searchModal: false,
  setSearchModal: (searchModal) => set({ searchModal }),
}));

export default function Search() {
  const { setSearchModal } = useSearchModalStore();

  return (
    <>
      <div className="flex gap-x-[24px] justify-center group mr-0">
        {/* Desktop */}
        <button
          className="hidden lg:flex border-[3px] border-transparent relative cursor-pointer flex-nowrap whitespace-nowrap text-center font-medium transition-colors 
                duration-300 ease-in-out text-white  rounded-lg xl:min-w-[320px] lg:w-full sm:py-1.5 
              sm:px-4 sm:text-center sm:text-14 hover:border-dark-pri  sm:bg-dark-pri/10 "
          onClick={() => setSearchModal(true)}
          aria-label="Search"
        >
          <div className="flex items-center gap-x-2 italic transition-color duration-300 ease-in-out text-light-gray group-hover:text-white">
            <IconSearch />
            <span className="hidden lg:flex">
              Search games, NFTs, 0xb00bS ...
            </span>
            <span className="hidden sm:flex lg:hidden">Search ...</span>
          </div>
        </button>
        {/* Mobile */}
        <Link
          href="/search-mobile"
          className="focus:outline-none focus:ring-0 focus:ring-transparent lg:hidden lg:border-[3px] border-transparent relative cursor-pointer flex-nowrap whitespace-nowrap text-center font-medium transition-colors 
                duration-300 ease-in-out text-white  rounded-lg xl:min-w-[320px] lg:w-full py-2 sm:py-1.5 
              px-4 sm:text-center sm:text-14 lg:hover:border-dark-pri  sm:bg-dark-pri/10 "
        >
          <div className="flex items-center gap-x-2 italic transition-color duration-300 ease-in-out text-light-gray group-hover:text-white">
            <IconSearch />
            <span className="hidden lg:flex">
              Search games, NFTs, 0xb00bS ...
            </span>
            <span className="hidden sm:flex lg:hidden">Search ...</span>
          </div>
        </Link>
      </div>
    </>
  );
}
