"use client";

import { useMagic } from "@/context/MagicProvider";
import { Tooltip } from "@mui/material";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { ImSpinner9 } from "react-icons/im";
import { useAccount } from "wagmi";
import { useLoginModalStore } from "../../magic/auth/LoginModal/ModalWrapper";
import IconSettings from "../Icons/IconSettings";

export default function Settings() {
  const [goToSettings, setGoToSettings] = useState(false);
  const { setOpenLoginModal } = useLoginModalStore();
  const router = useRouter();
  const { magic } = useMagic();
  const { isConnected } = useAccount();

  const handleSettingsClick = async () => {
    setGoToSettings(true);
    if (isConnected) {
      router.push("/settings");
      setGoToSettings(false);
      return;
    }
    const loggedIn = await magic?.user?.isLoggedIn();
    if (loggedIn === true) {
      router.push("/settings");
    } else {
      setOpenLoginModal(true);
    }
    setGoToSettings(false);
  };
  return (
    <>
      {/* @ts-ignore */}
      <Tooltip title="Settings" arrow placement="right">
        <button
          className="mx-auto "
          onClick={handleSettingsClick}
          aria-label="Go to Settings"
        >
          {!goToSettings ? (
            <IconSettings />
          ) : (
            <>
              {/* @ts-ignore */}
              <ImSpinner9 className="text-dark-pri/70 animate-spin w-6 h-6" />
            </>
          )}
        </button>
      </Tooltip>
    </>
  );
}
