"use client";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import { create } from "zustand";

interface AlertState {
  openAlert: boolean;
  setOpenAlert: (openContact: boolean) => void;
  alertMessage: string | React.ReactNode;
  setAlertMessage: (alertMessage: string | React.ReactNode) => void;
  alertType?: string;
  setAlertType: (alertType: string) => void;
}

export const useAlertStore = create<AlertState>((set) => ({
  openAlert: false,
  setOpenAlert: (openAlert) => set({ openAlert }),
  alertMessage: "",
  setAlertMessage: (alertMessage) => set({ alertMessage }),
  // @ts-ignore
  alertType: "default",
  setAlertType: (alertType) => set({ alertType }),
}));

export default function Alert() {
  // type?: "success" | "error" | "warning" | "info" | "default" | any;
  const messageContainer = (children: React.ReactNode) => (
    <div className="flex items-center gap-x-3">{children}</div>
  );

  const { openAlert, setOpenAlert, alertMessage, alertType } = useAlertStore();

  const messageContent = () => {
    if (alertType === "success") {
      return messageContainer(
        <>
          <img
            src="/memes/success-kid.png"
            className="w-[62px] h-[62px] min-w-[62px] rounded-full object-cover shadow-xl shadow-black/30"
          />
          <div className="grid gap-1 justify-start">
            <div className="text-left font-medium text-lg tracking-wide">
              Success!
            </div>
            {alertMessage}
          </div>
        </>
      );
    } else if (alertType === "error") {
      return messageContainer(
        <>
          <div className="grid gap-1">
            <div className="font-medium text-lg tracking-wide">Hmm...</div>
            {alertMessage}
          </div>
        </>
      );
    } else {
      return <div className="text-[16px]">{alertMessage}</div>;
    }
  };

  const gradientBackground =
    alertType === "success"
      ? "linear-gradient(135deg, #9D85FF, #7654FF, #9D85FF)"
      : alertType === "error"
        ? "linear-gradient(135deg, #ff758c, #ea335e)"
        : "linear-gradient(135deg, #7654FF, #7654FF)";

  return (
    <>
      {/* @ts-ignore */}
      <Snackbar
        open={openAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={() => setOpenAlert(false)}
        autoHideDuration={4000}
        message={messageContent()}
        sx={{
          "& .MuiPaper-root": {
            background: gradientBackground,
            borderRadius: "8px",
            padding: "12px 28px",
          },
        }}
      />
    </>
  );
}
