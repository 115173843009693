import { Dispatch, SetStateAction } from "react";
import { useContactModalStore } from "../ContactForm";

type Props = {
  setSearchModal?: Dispatch<SetStateAction<boolean>>;
};

export default function Contact({ setSearchModal }: Props) {
  const { setOpenContact } = useContactModalStore();
  const handleClickMessage = () => {
    setSearchModal && setSearchModal(false);
    setOpenContact(true);
  };

  return (
    <>
      <div className="grid mt-6 sm:mt-0 gap-4">
        <div className="pb-2 ">
          <button onClick={handleClickMessage} aria-label="Contact Us">
            <div className=" text-xl text-left font-bold flex items-center gap-x-4">
              <div className="flex bg-gradient-to-br  from-dark-purple to-dark-default items-center justify-center p-1 rounded-lg w-[40px] h-[40px]">
                💬
              </div>

              <span className="gradientTextGray">Contact us</span>
            </div>
          </button>
        </div>
      </div>
    </>
  );
}
