import { Tooltip } from "@mui/material";
import {
  ETHEREUM_MAINNET,
  ETHEREUM_SEPOLIA,
  POLYGON_MAINNET,
  POLYGON_AMOY,
  OPTIMISM_MAINNET,
  OPTIMISM_SEPOLIA,
  ARBITRUM_MAINNET,
  ARBITRUM_SEPOLIA,
  BASE_MAINNET,
  BASE_SEPOLIA,
  // POLYGON_ZKEVM,
} from "@/constants/chains";
import { NETWORKS } from "@/constants/chains";
import { capitalizeWords } from "@/utils/misc";
import Image from "next/image";

const ethLogo = "/network/1.png";
const polygonLogo = "/network/137.png";
const optimismLogo = "/network/10.png";
const arbitrumLogo = "/network/42161.png";
const baseLogo = "/network/8453.png";

const logos = {
  [ETHEREUM_MAINNET.chainId]: ethLogo,
  [ETHEREUM_SEPOLIA.chainId]: ethLogo,
  [POLYGON_MAINNET.chainId]: polygonLogo,
  [POLYGON_AMOY.chainId]: polygonLogo,
  [OPTIMISM_MAINNET.chainId]: optimismLogo,
  [OPTIMISM_SEPOLIA.chainId]: optimismLogo,
  [ARBITRUM_MAINNET.chainId]: arbitrumLogo,
  [ARBITRUM_SEPOLIA.chainId]: arbitrumLogo,
  [BASE_MAINNET.chainId]: baseLogo,
  [BASE_SEPOLIA.chainId]: baseLogo,
};

type Props = {
  imgStyle?: string;
  chainId?: number;
};

export default function BlockchainLogo({ imgStyle, chainId }: Props) {
  const network = NETWORKS.find((config) => config.chainId === chainId);

  // Beautify name, replace underscores with spaces and then capitalize
  const networkName = network
    ? capitalizeWords(network.name.replace(/_/g, " "))
    : "";
  const imgSrc = logos[chainId || ""];

  return (
    <>
      {/* @ts-ignore */}
      <Tooltip title={networkName} placement="top" arrow>
        {imgSrc && (
          <img
            src={imgSrc}
            className={`object-contain h-[22px] max-h-[22px] w-[22px] min-w-[22px] ${imgStyle}`}
            alt={`${networkName} logo`}
          />
        )}
      </Tooltip>
    </>
  );
}
