"use client";
import {
  imageFileTypes,
  modelFileTypes,
  audioFileTypes,
  videoFileTypes,
} from "@/constants/media";
import LoadingDots from "../Loading/LoadingDots";
import SkeletonLoader from "../SkeletonLoader";
import dynamic from "next/dynamic";
import mime from "mime-types";
import { useEffect, useState, useMemo } from "react";
import Image from "next/image";

const GoogleModel = dynamic(() => import("./3DModel/GoogleModel"), {
  ssr: false,
  loading: () => (
    <div className="grid min-h-[200px] items-center justify-center">
      <LoadingDots />
    </div>
  ),
});

export const SkeletonLoaderMedia = () => (
  <SkeletonLoader containerShape="h-[300px] w-full rounded-xl md:h-[600px]" />
);

type Props = {
  src: string;
  fallback?: string;
  alt?: string;
  className?: string;
  nftImageFileType?: string;
  nftCached?: string;
};

export default function MediaWrangler({
  src,
  nftImageFileType,
  fallback,
  nftCached,
}: Props) {
  const [fileType, setFileType] = useState("image");
  const [imgSrc, setImgSrc] = useState(src);
  const imgNotFound = "/image-not-found.png";

  const isValidUrl = useMemo(
    () => (url: string) => {
      try {
        new URL(url);
        return true;
      } catch {
        return false;
      }
    },
    []
  );

  const determineFileType = (extension: string | null) => {
    if (!extension) return "image";
    if (imageFileTypes.includes(extension) || extension === "svg")
      return extension === "svg" ? "svg" : "image";
    if (modelFileTypes.includes(extension)) return "model";
    if (audioFileTypes.includes(extension)) return "audio";
    if (videoFileTypes.includes(extension)) return "video";
    return "image";
  };

  useEffect(() => {
    const validSrc = isValidUrl(src)
      ? src
      : nftCached && isValidUrl(nftCached)
        ? nftCached
        : imgNotFound;
    setImgSrc(validSrc);

    const fileExtension = imgSrc.split(".").pop();
    if (fileExtension) {
      setFileType(determineFileType(fileExtension));
    } else if (nftImageFileType) {
      const nftFileType = nftImageFileType.split("/")[1];
      setFileType(determineFileType(nftFileType));
    } else {
      getFileTypeFromUrl(imgSrc).then((ext) =>
        setFileType(determineFileType(ext))
      );
    }
  }, [src, nftCached, imgNotFound, isValidUrl]);

  const getFileTypeFromUrl = useMemo(
    () =>
      async (url: string): Promise<string | null> => {
        try {
          const response = await fetch(url, { method: "HEAD" });
          const contentType = response.headers.get("content-type");
          return contentType ? mime.extension(contentType) : null;
        } catch (error) {
          console.error("Error fetching content type:", error);
          return null;
        }
      },
    []
  );

  const handleError = () => {
    if (nftImageFileType) {
      const nftFileType = nftImageFileType.split("/")[1];
      if (nftFileType !== fileType) {
        setFileType(determineFileType(nftFileType));
        return;
      }
    }
    setImgSrc(imgSrc === nftCached ? imgNotFound : nftCached || imgNotFound);
  };

  if (!imgSrc) {
    return (
      <img
        src={imgNotFound}
        width={500}
        height={500}
        alt="NFT Image"
        className="h-full w-full object-contain object-center sm:rounded-lg"
      />
    );
  }

  switch (fileType) {
    case "image":
      return (
        <img
          key={fileType}
          src={imgSrc}
          alt="NFT Image"
          className="h-full w-full object-contain object-center sm:rounded-lg max-h-screen"
          onError={handleError}
        />
      );
    case "svg":
      return (
        <embed
          key={fileType}
          src={imgSrc}
          type="image/svg+xml"
          className="h-full w-full object-cover object-center sm:rounded-lg"
        />
      );
    case "model":
      return <GoogleModel key={fileType} model={imgSrc} />;
    case "audio":
      return (
        <audio key={fileType} controls src={imgSrc} className="w-full h-full" />
      );
    case "video":
      return (
        <>
          <video
            key={fileType}
            src={imgSrc}
            className="flex h-full w-full object-contain object-center sm:rounded-lg"
            autoPlay
            muted
            loop
            playsInline // Required for iOS inline video
            // controls
            poster={nftCached || fallback}
            onError={handleError}
          />
          {/* <Image
            src={
              nftCached && nftCached.length > 0
                ? nftCached
                : fallback && fallback.length > 0
                ? fallback
                : imgNotFound
            }
            width={500}
            height={500}
            alt="NFT Image"
            className="lg:hidden h-full w-full object-contain object-center sm:rounded-lg max-h-screen"
            onError={handleError}
          /> */}
        </>
      );
    case "text/html":
      return (
        <iframe
          key={fileType}
          src={imgSrc}
          sandbox="allow-same-origin allow-scripts"
          frameBorder="0"
          allowFullScreen
          className="h-auto min-h-[500px] w-full object-cover object-center"
        ></iframe>
      );
    default:
      return (
        <img
          key={fileType}
          src={imgNotFound}
          width={500}
          height={500}
          alt="NFT Image"
          className="h-full w-full object-contain object-center sm:rounded-lg"
        />
      );
  }
}
