export const jokesLocal = [
  { joke: "Why don't eggs tell jokes? Because they might crack up!" },
  { joke: "What time did the man go to the dentist? Tooth-hurty." },
  { joke: "Did you hear about the circus fire? It was in tents." },
  {
    joke: "Why don't scientists trust atoms? Because they make up everything!",
  },
  { joke: "How do you make a tissue dance? Put a little boogey in it!" },
  { joke: "Why couldn't the bicycle stand up by itself? It was two-tired." },
  { joke: "What do you call an alligator in a vest? An investigator!" },
  { joke: "What's orange and sounds like a parrot? A carrot." },
  {
    joke: "What do you get when you cross a snowman and a vampire? Frostbite.",
  },
  {
    joke: "What did the janitor say when he jumped out of the closet? “Supplies!”",
  },
  {
    joke: "Why did the scarecrow win an award? Because he was outstanding in his field!",
  },
  { joke: "What do you call cheese that isn't yours? Nacho cheese." },
  { joke: "How does a penguin build its house? Igloos it together." },
  { joke: "Why can’t you give Elsa a balloon? Because she will let it go." },
  { joke: "What do you call a fake noodle? An impasta." },
  { joke: "Why was the math book sad? Because it had too many problems." },
  { joke: "What do you call a belt made out of watches? A waist of time." },
  { joke: "What do you call a man with a rubber toe? Roberto." },
  { joke: "How do you catch a squirrel? Climb a tree and act like a nut!" },
  { joke: "What’s brown and sticky? A stick." },
  { joke: "Why don't skeletons fight each other? They don't have the guts." },
  { joke: "What do you call a boomerang that won’t come back? A stick." },
  { joke: "Why did the coffee file a police report? It got mugged." },
  { joke: "How do you make holy water? You boil the hell out of it." },
  { joke: "What's a ghost's favorite dessert? Boo-berries!" },
  { joke: "Why did the bicycle fall over? It was two tired." },
  { joke: "How do you organize a space party? You planet." },
  {
    joke: "Why did the golfer bring two pairs of pants? In case he got a hole in one.",
  },
  { joke: "What do you call a bear with no teeth? A gummy bear." },
  {
    joke: 'Why can\'t you hear a pterodactyl go to the bathroom? Because the "P" is silent.',
  },
  { joke: "What did the fish say when it hit the wall? Dam." },
  {
    joke: "What’s the best thing about Switzerland? I don’t know, but the flag is a big plus.",
  },
  { joke: "What do you get from a pampered cow? Spoiled milk." },
  {
    joke: "Did you hear about the restaurant on the moon? Great food, no atmosphere.",
  },
  { joke: "Why did the tomato turn red? Because it saw the salad dressing!" },
  { joke: "What do you call a pile of cats? A meowtain." },
  {
    joke: "What's the best way to watch a fly fishing tournament? Live stream.",
  },
  {
    joke: "What do you call a factory that makes okay products? A satisfactory.",
  },
  {
    joke: 'What did one wall say to the other wall? "I\'ll meet you at the corner!"',
  },
  { joke: "Why don’t skeletons fight each other? They don’t have the guts." },
  {
    joke: "What did the grape do when he got stepped on? He let out a little wine!",
  },
  { joke: "What do you call a man who can’t stand? Neil." },
  {
    joke: "Why can't a nose be 12 inches long? Because then it would be a foot.",
  },
  { joke: "What do you call an elephant that doesn’t matter? An irrelephant." },
  {
    joke: "Why couldn't the leopard play hide and seek? Because he was always spotted.",
  },
  { joke: "What do you call a can opener that doesn’t work? A can’t opener." },
];
